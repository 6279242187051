import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/assets/js/media_750"
import '@/assets/css/base.css'
import '@/assets/css/common.css'
import Vant from 'vant'
import 'vant/lib/index.css'

Vue.use(Vant)
Vue.config.productionTip = false

Vue.prototype.backSpace = () => router.push("home")

router.beforeEach(async (to, from, next) => {
  const { name } = to;
  if (name === 'login') {
    return next();
  }
  if (sessionStorage.getItem("token")) {
    return next();
  } else {
    return next({
      name: 'login',
      params: { back: to },
    });
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
