import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  if (typeof (location) == "string") {
    var Separator = "&";
    if (location.indexOf('?') == -1) { Separator = '?'; }
    location = location + Separator + "random=" + Math.random();
  }
  return routerPush.call(this, location).catch(error => error)
}

export default new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'login',
      component: resolve => require(['@/page/login.vue'], resolve)
    },
    {
      path: '/login', //首页
      name: 'login',
      component: resolve => require(['@/page/login.vue'], resolve)
    },
    {
      path: '/home',
      name: 'home',
      component: resolve => require(['@/page/home.vue'], resolve)
    },
    {
      path: '/buy',
      name: 'buy',
      component: resolve => require(['@/page/buy.vue'], resolve)
    },
    {
      path: '/new',
      name: 'new',
      component: resolve => require(['@/page/new.vue'], resolve)
    },
    {
      path: '/have',
      name: 'have',
      component: resolve => require(['@/page/have.vue'], resolve)
    },
    {
      path: '/pay',
      name: 'pay',
      component: resolve => require(['@/page/pay.vue'], resolve)
    },
    {
      path: '/list',
      name: 'list',
      component: resolve => require(['@/page/list.vue'], resolve)
    },
    {
      path: '/payoff',
      name: 'payoff',
      component: resolve => require(['@/page/payoff.vue'], resolve)
    },
    {
      path: '/success',
      name: 'success',
      component: resolve => require(['@/page/success.vue'], resolve)
    },
    {
      path: '/orderList',
      name: 'orderList',
      component: resolve => require(['@/page/orderList.vue'], resolve)
    },
    {
      path: '/user',
      name: 'user',
      component: resolve => require(['@/page/user.vue'], resolve)
    },
    {
      path: '/preview',
      name: 'preview',
      component: resolve => require(['@/page/preview.vue'], resolve)
    },
    {
      path: '/dataList',
      name: 'dataList',
      component: resolve => require(['@/page/dataList.vue'], resolve)
    }
  ]
})