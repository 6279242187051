<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  watch: {},
  computed: {},
};
</script>
<style lang="less">
// html,body,#app{ width:100%;height:100%; background-color: #f4f5f7;}
</style>
